import React from 'react';
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
import logo from '../../images/logo-blue.svg';
import FormChat from '../FormChat';

const Footer = () => {
  const {t} = useTranslation();
  return (
    <footer className="text-gray-600 body-font bg-blue-50 mt-10 bg-footer pt-24">
      <div className="container px-5 py-12 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-80 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <span className="logo-f">
            <Link to="/">
              <img className="h-10" alt="IOHUB" src={logo} />
            </Link>
          </span>
          <p className="ml-1.5 mt-2 text-blue-900 text-base">{t('flogo.title')}</p>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-14 lg:pl-5 xl:pl-32 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/4 md:w-1/2 w-full">
            <h2 className="sm-title">{t('fmenu.s.products')}</h2>
            <nav className="nav-f">
              <li><Link to="/workflow">{t('hmenu.workflow')}</Link></li>
              <li><Link to="/connectors">{t('hmenu.connectors')}</Link></li>
              <li><Link to="/pricing">{t('hmenu.pricing')}</Link></li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full">
            <h2 className="sm-title">{t('fmenu.s.solutions')}</h2>
            <nav className="nav-f">
              <li><Link to="/solutions">{t('hmenu.solutions')}</Link></li>
              <li><Link to="/dx/mixercms">{t('fmenu.mixercms')}</Link></li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full">
            <h2 className="sm-title">{t('fmenu.s.company')}</h2>
            <nav className="nav-f">
              <li><Link to="/about">{t('hmenu.about')}</Link></li>
              <li><Link to="/legal">{t('fmenu.legal')}</Link></li>
              <li><Link to="/termsofservice">{t('fmenu.terms')}</Link></li>
              <li><Link to="/privacypolicy">{t('fmenu.privacy')}</Link></li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full z-50">
            <h2 className="sm-title">{t('fmenu.s.social')}</h2>
            <span className="nav-f flex items-center justify-center md:justify-start">
              <a href="https://vimeo.com/iohubtech" alt="Vimeo" target="_blank" rel="noreferrer">
                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 mr-2" viewBox="0 0 24 24">
                  <path d="M22.875 10.063c-2.442 5.217-8.337 12.319-12.063 12.319-3.672 0-4.203-7.831-6.208-13.043-.987-2.565-1.624-1.976-3.474-.681l-1.128-1.455c2.698-2.372 5.398-5.127 7.057-5.28 1.868-.179 3.018 1.098 3.448 3.832.568 3.593 1.362 9.17 2.748 9.17 1.08 0 3.741-4.424 3.878-6.006.243-2.316-1.703-2.386-3.392-1.663 2.673-8.754 13.793-7.142 9.134 2.807z"/>
                </svg>
              </a>
              <a className="ml-3" href="https://www.facebook.com/iohub.tech" alt="Facebook" target="_blank" rel="noreferrer">
                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              <a className="ml-3" href="https://twitter.com/iohubtech" alt="Twitter" target="_blank" rel="noreferrer">
                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
              </a>
            </span>
            <nav className="nav-f -mt-9">
              <li>
                <a href="mailto:hello@iohub.tech">
                  <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3 inline" viewBox="0 0 512 512">
                    <path d="M467,61H45C20.218,61,0,81.196,0,106v300c0,24.72,20.128,45,45,45h422c24.72,0,45-20.128,45-45V106
                      C512,81.28,491.872,61,467,61z M460.786,91L256.954,294.833L51.359,91H460.786z M30,399.788V112.069l144.479,143.24L30,399.788z
                      M51.213,421l144.57-144.57l50.657,50.222c5.864,5.814,15.327,5.795,21.167-0.046L317,277.213L460.787,421H51.213z M482,399.787
                      L338.213,256L482,112.212V399.787z"/>
                  </svg> hello@iohub.tech
                </a>
              </li>
              <li>
                <a href={`tel:${t('fmenu.s.phone')}`}>
                  <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3 inline" viewBox="0 0 512 512">
                    <path d="M498.827,377.633l-63.649-63.649c-17.548-17.547-46.102-17.547-63.649,0l-28.931,28.931
                      c-13.294,13.294-34.926,13.29-48.215,0.005l-125.4-125.507c-13.325-13.325-13.327-34.892,0-48.219
                      c4.66-4.66,18.041-18.041,28.931-28.931c17.471-17.47,17.715-45.935-0.017-63.665l-63.632-63.432
                      C116.717-4.381,88.164-4.381,70.663,13.12C57.567,26.102,53.343,30.29,47.471,36.111c-63.28,63.279-63.28,166.242-0.003,229.519
                      l198.692,198.796c63.428,63.429,166.088,63.434,229.521,0l23.146-23.145C516.375,423.733,516.375,395.181,498.827,377.633z
                      M91.833,34.382c5.849-5.849,15.365-5.85,21.233,0.016l63.632,63.432c5.863,5.863,5.863,15.352,0,21.216l-10.609,10.608
                      l-84.81-84.81L91.833,34.382z M267.38,443.213L68.687,244.415c-48.958-48.958-51.649-125.833-8.276-178.006l84.564,84.564
                      c-22.22,25.189-21.294,63.572,2.787,87.653l125.396,125.501c0.001,0.001,0.003,0.003,0.004,0.004
                      c24.055,24.056,62.436,25.042,87.656,2.792l84.566,84.566C393.377,494.787,316.675,492.508,267.38,443.213z M477.612,420.065
                      l-10.609,10.609l-84.865-84.866l10.607-10.608c5.85-5.849,15.367-5.85,21.217,0l63.649,63.649
                      C483.461,404.699,483.461,414.217,477.612,420.065z"/>
                  </svg> {t('fmenu.s.phone')}
                </a>
              </li>
              <li>
                <Link to="/inquiry">
                  <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3 inline" viewBox="0 0 512 512">
                    <path d="m310 512h-190c-66.168 0-120-53.832-120-120v-272c0-66.168 53.832-120 120-120h272c66.168 0 120 53.832 120 120v191.5c0 11.046-8.954 20-20 20s-20-8.954-20-20v-191.5c0-44.112-35.888-80-80-80h-272c-44.112 0-80 35.888-80 80v272c0 44.112 35.888 80 80 80h190c11.046 0 20 8.954 20 20s-8.954 20-20 20zm102-386c0-11.046-8.954-20-20-20h-183c-11.046 0-20 8.954-20 20s8.954 20 20 20h183c11.046 0 20-8.954 20-20zm0 80c0-11.046-8.954-20-20-20h-272c-11.046 0-20 8.954-20 20s8.954 20 20 20h272c11.046 0 20-8.954 20-20zm-200 80c0-11.046-8.954-20-20-20h-72c-11.046 0-20 8.954-20 20s8.954 20 20 20h72c11.046 0 20-8.954 20-20zm-112-162c0 13.807 11.193 25 25 25s25-11.193 25-25-11.193-25-25-25-25 11.193-25 25zm390.619 366.618c28.462-28.462 28.462-74.774 0-103.237l-70.109-70.108c-20.263-20.264-45.827-33.942-73.926-39.559l-56.664-11.326c-6.554-1.313-13.334.742-18.062 5.47-4.728 4.729-6.78 11.506-5.47 18.063l11.326 56.664c5.617 28.1 19.296 53.662 39.558 73.926l70.109 70.108c13.788 13.787 32.12 21.381 51.619 21.381s37.831-7.594 51.619-21.382zm-151.875-173.68c20.329 4.063 38.822 13.958 53.481 28.618l70.109 70.108c12.867 12.867 12.867 33.803 0 46.67-6.232 6.233-14.52 9.666-23.334 9.666s-17.102-3.433-23.334-9.666l-70.109-70.108c-14.659-14.66-24.555-33.153-28.618-53.481l-5.447-27.253z"/>
                  </svg> {t('fmenu.inquiry')}
                </Link>
              </li>
              {/* <li>
                <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3 inline" viewBox="0 0 512 512">
                  <g>
                    <path d="M256,0C156.748,0,76,80.748,76,180c0,33.534,9.289,66.26,26.869,94.652l142.885,230.257
                      c2.737,4.411,7.559,7.091,12.745,7.091c0.04,0,0.079,0,0.119,0c5.231-0.041,10.063-2.804,12.75-7.292L410.611,272.22
                      C427.221,244.428,436,212.539,436,180C436,80.748,355.252,0,256,0z M384.866,256.818L258.272,468.186l-129.905-209.34
                      C113.734,235.214,105.8,207.95,105.8,180c0-82.71,67.49-150.2,150.2-150.2S406.1,97.29,406.1,180
                      C406.1,207.121,398.689,233.688,384.866,256.818z"/>
                  </g>
                  <g>
                    <path d="M256,90c-49.626,0-90,40.374-90,90c0,49.309,39.717,90,90,90c50.903,0,90-41.233,90-90C346,130.374,305.626,90,256,90z
                        M256,240.2c-33.257,0-60.2-27.033-60.2-60.2c0-33.084,27.116-60.2,60.2-60.2s60.1,27.116,60.1,60.2
                      C316.1,212.683,289.784,240.2,256,240.2z"/>
                  </g>
                </svg> {t('fmenu.s.address')}
              </li> */}
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-blue-50">
        <div className="my-cont flex flex-wrap flex-col sm:flex-row border-t border-yellow-300">
          <p className="text-gray-500 text-sm text-center sm:text-left">© {new Date().getFullYear()} {t('fmenu.powered')}</p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">

          </span>
        </div>
      </div>
      <FormChat />
    </footer>
  )
};

export default Footer;
