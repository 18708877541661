import {useEffect} from 'react';

const FormChat = () => {
  useEffect(() => {
    if (document.getElementById('hs-script-loader') == null) {
      let aScript = document.createElement('script');
      aScript.type = 'text/javascript';
      aScript.id= 'hs-script-loader';
      aScript.async = 'async';
      aScript.defer = 'defer';
      aScript.src = "//js-na1.hs-scripts.com/20091772.js";
      document.body.appendChild(aScript);
    }
  }, []);
  return null;
};

export default FormChat;
