import React from 'react';
import PropTypes from 'prop-types';
import {Helmet, useTranslation} from 'gatsby-plugin-react-i18next';
import {useStaticQuery, graphql} from 'gatsby';

const Seo = ({page, meta, children}) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );
  const {t} = useTranslation();
  const titleStr = page ? "" : " · IOHUB";
  const metaTitle = (t('page.title') || site.siteMetadata.title) + titleStr;
  const metaDescription = t('page.desc') || site.siteMetadata.description;
  // const metaAuthor = site.siteMetadata.author;
  const metaUrl = site.siteMetadata.siteUrl;

  return (
    <Helmet
      title={metaTitle}
      defaultTitle={site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:url`,
          content: metaUrl
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: '@iohubtech'
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      {children}
    </Helmet>
  );
}

Seo.defaultProps = {
  meta: [],
  description: ``
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
};

export default Seo;