import React, { useState, useEffect } from 'react';
import LangSelector from '../LangSelector';
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
import logo from '../../images/logo.svg';

const Header = () => {
  const {t, i18n} = useTranslation();
  const [scrolled, setScrolled] = useState(false);
  const navScrolled = () => {
    setScrolled(window.pageYOffset > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', navScrolled);
    return () => { 
      window.removeEventListener('scroll', navScrolled); 
    };
  }, []);

  return (
    <header onScroll={navScrolled}
      className={`text-white bg-blue-900 lg:sticky top-0 z-50 transition duration-500 ${scrolled ? "lg:border-b border-yellow-400 shadow-2xl" : ""}`}>
      <div className="container mx-auto flex flex-wrap px-5 py-3 flex-col lg:flex-row items-center">
        <span className="logo-h md:mb-4 lg:mb-0">
          <Link to="/">
            <img className="h-10" alt="IOHUB" src={logo} />
          </Link>
        </span>
        <nav className="nav-h">
          <Link to="/workflow" activeClassName="text-yellow-300">{t('hmenu.workflow')}</Link>
          <Link to="/solutions" activeClassName="text-yellow-300">{t('hmenu.solutions')}</Link>
          <Link to="/connectors" activeClassName="text-yellow-300">{t('hmenu.connectors')}</Link>
          <Link to="/pricing" activeClassName="text-yellow-300">{t('hmenu.pricing')}</Link>
          <Link to="/about" activeClassName="text-yellow-300">{t('hmenu.about')}</Link>
        </nav>
        <span className="inline-flex items-center mt-4 lg:mt-0">
          <a className="btn" href={`https://app.iohub.tech/?lng=${i18n.language.toLowerCase().substring(0, 2)}`}>{t('header.signup')}</a>
          <LangSelector />
        </span>
      </div>
    </header>
  )
};

export default Header;
