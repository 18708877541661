import React from 'react';
import {StaticImage} from "gatsby-plugin-image"
import {Link, useI18next} from 'gatsby-plugin-react-i18next';

const LangUI = [];
LangUI['en'] = {
  lbl: "English",
  img: <StaticImage src="../images/en.png" alt="English" />
};
LangUI['vi'] = {
  lbl: "Tiếng Việt",
  img: <StaticImage src="../images/vi.png" alt="Tiếng Việt" />
};

const LangSelector = () => {
  const {language, languages, originalPath} = useI18next();
  return (
    <div className="items-center justify-center ml-2">
      <div className="dropdown">
        <span>
          <button className="w-8 h-8 p-0.5 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-full focus:outline-none focus:border-blue-300 focus:shadow-outline-blue" type="button">
            {LangUI[language].img}
          </button>
        </span>
        <div className="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
          <div className="absolute w-40 right-0 mt-2 origin-top-right bg-white border border-gray-300 divide-y divide-gray-200 rounded-md shadow-lg overflow-hidden">
          {languages.map((lng) => (
            <div key={lng} className="py-2 px-5 text-blue-900 hover:text-blue-800 hover:bg-yellow-300">
              <Link to={originalPath} language={lng} className="flex">
                {LangUI[lng].lbl}
              </Link>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LangSelector;
